import React from 'react';

export default function BookCard({ inventory }) {

  return (
    <div className="flex items-center basis-1/8 justify-center display-inline bg-white mt-12">
      <div className="h-52 bg-transparent group">
        <div className="relative">
          <img className="absolute w-48 h-60 rounded-sm" src={inventory[2]} alt="boek" />
          <div className="w-48 h-60 rounded-sm bg-transparent flex flex-col items-center justify-center group-hover:animate-fade-in-up">
            <p className="bottom-6 text-center group-hover:bottom-36 absolute text-xl font-bold text-white">{inventory[1]}</p>
            <p className="bottom-24 ml-2 mt-1 text-xl text-white group-hover:absolute">{inventory[0]}</p>
            <p className="bottom-16 text-xl ml-2 mt-1 text-white group-hover:absolute">Schadegraad</p>
            <p className="bottom-8 text-2xl ml-2 mt-1 text-white group-hover:absolute">{inventory[4]}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
