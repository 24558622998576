import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from "react-i18next";

export default function RegisterConfirmation({open, handleClose}) {
  const { t } = useTranslation();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      > 
          <DialogTitle id="alert-dialog-title">
            {t('RegisterConfirmation')}
          </DialogTitle> 
        <DialogActions sx={{ justifyContent: 'center', p: 2 }}>
          <button onClick={handleClose}>
            Ok
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}