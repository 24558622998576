import React from 'react';

export default function BookList({inventory}) {

  return (
    <div href="#" className="flex flex-col items-center bg-white mt-6 rounded-lg border shadow-md md:flex-row w-full hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
      <img className="object-cover w-24 rounded-t-lg md:rounded-none md:rounded-l-lg" src={inventory[2]} alt="boek"></img>
      <div className="flex flex-col justify-between p-4 ml-4 leading-normal w-full">
        <div className="flex flex-row justify-between items-center leading-normal">
          <h3 className="mb-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white">{inventory[1]}</h3>
          <p className="mr-8 font-semibold text-xl text-gray-700">{inventory[0]}</p>
        </div>
        <div className="flex flex-row justify-between items-center leading-normal">
          <p className="mb-3 font-bold text-2xl text-gray-700 dark:text-gray-400">Schadegraad {inventory[4]}</p>
          <p className="mr-8 font-semibold text-2xl text-gray-700">{inventory[3]}</p>
        </div>
      </div>
    </div>
  ); 
};