import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../../config.json';

const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL || config.base_url;

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({baseURL: 
    	BASE_URL,
	prepareHeaders: (headers) => {
		headers.set('Authorization', `Bearer ${localStorage.getItem(config.token_key)}`)
    return headers;
	}
}),
  tagTypes: ['Inventories'],
  endpoints: builder => ({
    getInventoryStepsIdentification: builder.query({
      query: () => ({url: 
	      `${BASE_URL}inventorysteps/identification`,
        transformResponse: (response) => response})
    }),
    getInventoryStepsBound: builder.query({
      query: () => ({url: 
	      `${BASE_URL}inventorysteps/bound`,
        transformResponse: (response) => response})
    }),  
    getFormCharStepsBound: builder.query({
      query: () => ({url: 
	      `${BASE_URL}inventorysteps/formchars`,
        transformResponse: (response) => response})
    }), 
    getInventoryStepsLoose: builder.query({
      query: () => ({url: 
	      `${BASE_URL}inventorysteps/loose`,
        transformResponse: (response) => response})
    }),  
    getInventoryFieldsByStepId: builder.query({
      query: (stepId) => ({url: 
        `${BASE_URL}inventoryfields/${stepId}`,
        transformResponse: (response) => response})
    }), 
    getInstructionsByInventoryFieldId: builder.query({
      query: (inventoryFieldId) => ({url:
        `${BASE_URL}inventoryFields/instructions/${inventoryFieldId}`,
        transformResponse: (response) => response})
    }),
    getDamageFieldsByInventoryFieldId: builder.query({
      query: (inventoryFieldId) => ({url:
        `${BASE_URL}inventoryFields/damage/${inventoryFieldId}`,
        transformResponse: (response) => response})
    }),
    getAllInventories: builder.query({
      query: () => ({url: 
        `${BASE_URL}inventory/inventories`,
        transformResponse: (response) => response}),
        providesTags: ['Inventories'],
    }),
    getInventorySummaries: builder.query({
      query: () => ({url: 
        `${BASE_URL}inventory/summary`}),
        transformResponse: (response) => response,
        keepUnusedDataFor: 10,
        refetchOnMountOrArgChange: true
    }),
  })
});

export const { 
  useGetInventoryFieldsByStepIdQuery,
  useGetInventoryStepsIdentificationQuery,
  useGetInventoryStepsBoundQuery,
  useGetFormCharStepsBoundQuery,
  useGetInventoryStepsLooseQuery,
  useGetDamageFieldsByInventoryFieldIdQuery,
  useGetAllInventoriesQuery,
  useGetInventorySummariesQuery,
  useGetInstructionsByInventoryFieldIdQuery,
  usePrefetch
} = apiSlice
