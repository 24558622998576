import React, { useEffect } from 'react';
import config from '../../config.json';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useGetInventoryStepsIdentificationQuery,
  useGetInventoryFieldsByStepIdQuery,
  useGetInventoryStepsBoundQuery,
  useGetFormCharStepsBoundQuery
} from '../../features/api/apiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { resetFormCharStep, resetStep } from '../../store/slices/stepperSlice';
import { Card } from '@mui/material';

export default React.memo(function InventoryStepCard({ stepId, order, cardTitle, cardIcon, step, isBound }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDamage = useSelector((state) => state.stepper.isDamage);
  const { data: inventoryStepsIdentification } = useGetInventoryStepsIdentificationQuery();
  const { data: inventoryStepsBound } = useGetInventoryStepsBoundQuery();
  const { data: formCharsBound } = useGetFormCharStepsBoundQuery();
  useGetInventoryFieldsByStepIdQuery(stepId);

  useEffect(() => {
    formCharsBound?.forEach((formchar) => {
      const img = new Image();
      img.src = `${config.base_imageUrl}${formchar.icon}`;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCardClick = () => {
    if (isDamage === false && step !== inventoryStepsIdentification.length) {
      navigate("/main/identification/" + stepId);
    } else if (isDamage === true && step !== inventoryStepsBound.length) {
      dispatch(resetFormCharStep());
      navigate("/main/damage/" + stepId);
    } else {
      dispatch(resetStep());
      navigate("/main/damage");
    };
  };

  return (
    <Card
      className={`flex items-center justify-center display-inline bg-white mt-10 md:mt-20 group relative${step === order ? 'w-72 h-64 sm:w-96 sm:h-80 shadow-md hover:scale-105 hover:shadow-xl'
        : 'w-80 h-72 sm:w-96 sm:h-80'} overflow-hidden rounded-sm bg-zinc-100 shadow-md duration-200 cursor-pointer flex flex-col items-center mx-6 
      ${step !== order ? 'opacity-40 cursor-not-allowed' : 'scale-105 shadow-xl'}`}
      sx={{ 
        backgroundColor: '#f5f5f5',
      }}
      onClick={step !== order ? undefined : handleCardClick}>
      <FontAwesomeIcon icon={cardIcon} color={'#334155'} className={isBound === true || isBound === 1 ? `h-20 w-20 mt-12 ${step === order ? "group-hover:h-0 duration-300" : ""}` :
        "h-20 w-20 sm:h-24 sm:w-24 mt-12 sm:mt-12"}
      />
      <div className="p-5">
        <p className={`text-center text-4xl mb-5 font-bold m-2 sm:m-6 text-slate-700`}>
          {cardTitle}
        </p>
      </div>
    </Card >
  )
});
