import React from 'react';
import { useForm } from 'react-hook-form';
import useFormPersist from 'react-hook-form-persist';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent } from '@mui/material';
import { useTranslation } from "react-i18next";
import { useGetDamageFieldsByInventoryFieldIdQuery } from '../../features/api/apiSlice';
import { updateInventoryData } from '../../store/slices/inventorySlice';
import InventoryField from '../layouts/InventoryField';
import { useDispatch } from 'react-redux';
import InventoryButton from '../layouts/InventoryButton';
import InventoryForm from './InventoryForm';

export default function DamagePopUp({
  inventoryFieldId,
  damagePresent,
  specificDamage,
  handleClose,
  // register,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { register, handleSubmit, clearErrors, formState: { errors }, watch, setValue } = useForm();
  useFormPersist('formData', {
    watch,
    setValue,
    storage: window.localStorage,
  });
  const { data: damageFields } = useGetDamageFieldsByInventoryFieldIdQuery(inventoryFieldId);
  const filteredDamageFields = damageFields?.filter((damageField) => damageField.specificDamageValue === specificDamage && damageField.type !== 'title');
  const damageTitle = damageFields?.find((damageField) => damageField.type === 'title' && damageField.specificDamageValue === specificDamage)?.description;

  const onClickError = (e) => {
    clearErrors(e.target.name);
  };

  const onTouch = (e) => {
    e.target.value = '';
    clearErrors(e.target.name);
  };

  const onSubmit = (data, event) => {
    dispatch(updateInventoryData(data));
    handleClose();
  };

  return (
    <Dialog
      open={damagePresent}
      fullWidth={true}
      maxWidth={'md'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disablePortal={true}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ textAlign: 'center', fontWeight: 'semi-bold', fontSize: 22, color: '#f8fafc', backgroundColor: '#0066cc', pt: 2 }}
      >
        {t('DamagePresent')} {damageTitle}{t('DamagePresentPt2')}
      </DialogTitle>
      <DialogContent sx={{ marginLeft: 2, textAlign: 'center' }}>
        <InventoryForm onSubmit={handleSubmit(onSubmit)}>
          {filteredDamageFields && filteredDamageFields.map((damageField, i) =>
                <InventoryField
                  key={damageField.id}
                  id={damageField.id}
                  label={damageField.description}
                  type={damageField.type}
                  imageName={damageField.imageName}
                  register={register}
                  errors={errors}
                  damagepresent={damagePresent}
                  onClick={onClickError}
                  onTouch={onTouch}
                  i={i}
                />)}
          <div className="mt-12 mb-8">
            <InventoryButton
              type="submit"
            >
              Ok
            </InventoryButton>
          </div>
        </InventoryForm>
      </DialogContent>
    </Dialog>
  );
};