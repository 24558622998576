import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axios } from "../axiosConfig";

const boundLabel = 'Is het document ingebonden?';

export const postInventory = createAsyncThunk('inventory/postInventory', async (data, { rejectWithValue }) => {
  try {
    const formattedData = Object.entries(data).map(([key,value]) => {
      return {
        inventoryFieldId: key,
        inventoryData: value, 
      }
    });
    const response = await axios.post('inventory', formattedData);
    return response.data;
  } catch (err) {
    return rejectWithValue('The completed inventory could not be sent to the database');
  };
});

export const updateInventory = createAsyncThunk('inventory/updateInventory', async (data, {rejectWithValue}) => {
  try {
    const response = await axios.post('inventory/update', {data});
    return response.data;
  } catch (err) {
    return rejectWithValue('The update could not be sent to the database');
  };
});

export const getAllInventories = createAsyncThunk('inventory/getAllInventories', async (rejectWithValue) => {
  try {
    const response = await axios.get('inventory/inventories');
    return response.data;
  } catch (err) {
    return rejectWithValue('The inventories could not be fetched');
  };
});

export const postImage = createAsyncThunk('inventory/postImage', async (data, { rejectWithValue }) => {
  try {
    const response = await axios.post('inventory/image', {data}, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    return response.data;
  } catch (err) {
    return rejectWithValue('The image could not be send to the backend');
  };
});

export const getImage = createAsyncThunk('inventory/getImage', async (name, { rejectWithValue }) => {
  try {
    const response = await axios.get(`inventory/image/${name}`);
    return response.config.url;
  } catch (err) {
    return rejectWithValue('The image could not be fetched from the backend');
  };
});

const inventorySlice = createSlice({
  name: "inventory",
  initialState: {
    inventory: [],
    inventories:[],
    image: [],
    downloadedImage: [],
    loading: false,
    error: "",
    bound: false,
    postedInventoryId: "",
  },
  reducers: {
    updateInventoryData: (state, action) => {
      state.inventory = {...(state.inventory), ...(action.payload)};
      if (state.inventory[boundLabel] === '1') {
        state.bound = true;
      } else {
        state.bound = false;
      };
    },
    changeBound: (state, action) => {
      if(action.payload === true) {
        state.bound = true;
      } else if (action.payload === false) {
        state.bound = false;
      } else {
        state.bound = undefined;
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postInventory.fulfilled, (state, action) => {
      state.loading = false;
      state.inventory = {};
      state.postedInventoryId = action.payload;
    });
    builder.addCase(postInventory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(postInventory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(updateInventory.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateInventory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateInventory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getAllInventories.fulfilled, (state, action) => {
      state.loading = false;
      state.inventories = action.payload;
    });
    builder.addCase(getAllInventories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllInventories.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(postImage.fulfilled, (state, action) => {
      state.loading = false;
      state.image = action.payload;
    });
    builder.addCase(postImage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(postImage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getImage.fulfilled, (state, action) => {
      state.loading = false;
      state.downloadedImage = action.payload;
    });
    builder.addCase(getImage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getImage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  }
});

export const { updateInventoryData, changeBound } = inventorySlice.actions;
export const showInventory = state => state.inventory.inventory;
export const showPostedInventoryId = state => state.inventory.postedInventoryId;
export const showAllInventories = state => state.inventory.inventories;
export const showInventoryImage = state => state.inventory.downloadedImage;
export const showBound = state => state.inventory.bound;
export default inventorySlice.reducer;