import React from 'react'

export default function InventoryButton(props) {
  const { children, ...rest } = props;

  return (
    <button
      className="bg-[#0066cc] text-white text-base text-center font-semibold rounded-sm w-full hover:bg-white hover:text-[#0066cc] hover:text-base hover:border hover:border-[#0066cc] sm:w-auto px-5 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      {...rest}
    >
      {children}
    </button>
  )
}
