import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axios } from "../axiosConfig";

export const getInventorySteps = createAsyncThunk('inventoryStep/getInventorySteps', async (rejectWithValue) => {
  try {
    const response = await axios.get('inventorysteps');
    return response.data;
  } catch (err) {
    return rejectWithValue('The inventorysteps could not be fetched');
  }
});

const inventoryStepSlice = createSlice({
  name: "inventoryStep",
  initialState: {
    data: [],
    loading: false,
    error: "",
    savedFormCharSteps: [],
  }, 
  reducers: {
    saveFormCharStep: (state, action) => {
      state.savedFormCharSteps = [...(state.savedFormCharSteps), action.payload]
    },
    emptySavedFormCharSteps: (state) => {
      state.savedFormCharSteps = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getInventorySteps.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getInventorySteps.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getInventorySteps.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  }
});

export const { saveFormCharStep, emptySavedFormCharSteps } = inventoryStepSlice.actions;
export const showInventorySteps = state => state.inventoryStep.data;
export const showSavedFormCharSteps = state => state.inventoryStep.savedFormCharSteps;
export default inventoryStepSlice.reducer;