import React,{ useEffect, useState }  from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { resetStep } from '../../store/slices/stepperSlice';

export default function HomePage() {
  const { t } = useTranslation();
  const dispatch  = useDispatch();

  const [visible, setVisible] = useState(false);

  const resetToBeginStep = () => {
    dispatch(resetStep());
  };

  useEffect(() => {
    setTimeout(() => {
      setVisible(true)
    }, 200)
  });

  return (
    <div className='relative text-center md:ml-12 md:mr-12'>
        <img src={require('../../assets/images/Foto5.JPG')} alt='Restauration of a book' className='h-screen w-full object-cover'/>
        <div className={`absolute inset-0 top-1/4 text-white  ${visible ? 'animate-fade-in-up' : 'invisible'}`}>
          <h1 className='text-5xl md:text-6xl font-bold mt-10 md:mr-4 sm:mr-0' >AppolloRate.</h1>
          <p className='text-lg md:text-3xl mt-3 md:mr-4 sm:mr-0' >{t('WelcomeText')}</p>
          <Link to="/main/identification" state="start" className="after:h-0">
            <button 
              data-cy="start_btn" 
              className='bg-transparent text-base ml-7 md:ml-0 text-white border-2 border-white
              hover:text-slate-700 hover:text-base hover:bg-white px-5 py-2 mt-3 sm:ml-8 mb-40'
              onClick={resetToBeginStep}
            >
              {t('StartInventory')}
            </button>
          </Link>
        </div>
      </div>
  )
}
