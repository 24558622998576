import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { decrementStep, incrementStep, resetIsDamage } from '../../store/slices/stepperSlice';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Steps from './Steps';
import Timer from '../helpers/Timer';

export default function PageStructure() {
  const step = useSelector((state) => state.stepper.step);
  const isDamage = useSelector((state) => state.stepper.isDamage);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const persistedStep = parseInt(JSON.parse(sessionStorage.getItem('persist:step')).step, 10);

  window.onpopstate = (event) => {
    if (persistedStep === 1 && location.pathname ==='/main/identification' && event.state.usr === null && isDamage === false) {
      dispatch(incrementStep());
    } else if (persistedStep === 1 && location.pathname ==='/main/identification' && event.state.usr === null && isDamage === true) {
      dispatch(resetIsDamage());
    } else if (persistedStep === 1 && location.pathname ==='/main/damage/7f28c5f9-d711-4cd6-ac15-d13d71abaa05' && event.state.usr === null && isDamage === true) {
      navigate("/main/damage");
      dispatch(incrementStep());
    } else if(persistedStep === 1 && location.pathname === '/main/identification/7f28c5f9-d711-4cd6-ac15-d13d71abaa02') {
      dispatch(incrementStep());
    } else if (persistedStep === 2 && location.pathname ==='/main/identification' && event.state.usr === 'start') {
      dispatch(decrementStep());
    } else if (persistedStep === 2 && location.pathname ==='/main/identification' && event.state.usr === null && isDamage === true) {
      navigate("/main/damage");
      dispatch(decrementStep());
    } else if(persistedStep === 2 && location.pathname === '/main/identification/7f28c5f9-d711-4cd6-ac15-d13d71abaa01'){
      dispatch(decrementStep());
    } else if(persistedStep === 2 && location.pathname === '/main/damage/7f28c5f9-d711-4cd6-ac15-d13d71abaa04' && isDamage === true){
      dispatch(decrementStep());
    } else if(persistedStep === 3 && location.pathname === '/main/identification' && isDamage === false) {
      dispatch(decrementStep());
    } else if(persistedStep === 3 && location.pathname === '/main/identification' && isDamage === true){
      navigate("/main/damage");
      dispatch(decrementStep());
    } else if (persistedStep === 3 && location.pathname ==='/main/damage/7f28c5f9-d711-4cd6-ac15-d13d71abaa05' && event.state.usr === null && isDamage === true) {
      dispatch(decrementStep());
    } else if(persistedStep === 4 && location.pathname === '/main/identification' && isDamage === true){
      navigate("/main/damage");
      dispatch(decrementStep());
    };
    // console.log(event.state.usr)
    // console.log(location.pathname)
  };

  return (
    <>
      <Steps step={step}/>
      <main >
        <Outlet />
      </main>
      <Timer />
    </>
  )
}
