import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from './components/helpers/ProtectedRoute';
import './i18n';

import HomeScreen from './components/screens/HomeScreen';
import IdentificationInventoryForm from './components/inventory/IdentificationInventoryForm';
import IdentificationScreen from './components/screens/IdentificationScreen';
import ScrollToTop from './components/helpers/ScrollToTop';
import PageStructure from './components/layouts/PageStructure';
import FinishPage from './components/inventory/FinishPage';
import OverviewScreen from './components/screens/OverviewScreen';
import LoginScreen from './components/screens/LoginScreen';
import RegisterScreen from './components/registerNewUser/RegisterScreen'
import HomePage from './components/layouts/HomePage';
import InventoryScreen from './components/screens/InventoryScreen';
import FormCharScreen from './components/screens/FormCharScreen';
import FormCharInventoryForm from './components/inventory/FormCharInventoryForm';

function App() {

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<HomeScreen/>} >
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/login" element={<LoginScreen />} />
          <Route 
            exact path="/register" 
            element={
              <ProtectedRoute>
                <RegisterScreen />
              </ProtectedRoute>
            }/>
          <Route 
            exact path="/overview" element={ <OverviewScreen /> }/>
        </Route>
        <Route exact path="/main" element={ <PageStructure /> }>
          <Route exact path="/main/identification" element={
            <ProtectedRoute>
              <IdentificationScreen />
            </ProtectedRoute>
            } />
          <Route exact path="/main/identification/:stepId" element={<IdentificationInventoryForm /> } />
          <Route exact path="/main/damage" element={ <InventoryScreen /> } />
          <Route exact path="/main/damage/:stepId" element={<FormCharScreen /> } />
          <Route exact path="/main/damage/inventory/:inventoryStepId/:stepId" element={<FormCharInventoryForm />} />
        </Route>
        <Route 
          exact path="/complete" 
          element={ <FinishPage /> }/>
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </>
  );
}

export default App;
