import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { resetIsDamage, setDamage, resetStep } from '../../store/slices/stepperSlice';
import { useGetInventoryStepsBoundQuery , useGetInventoryStepsLooseQuery } from '../../features/api/apiSlice';
import { changeBound, showBound } from '../../store/slices/inventorySlice';
import FormCharStepCard from '../layouts/FormCharStepCard';
import InventoryButton from '../layouts/InventoryButton';

export default React.memo(function InventoryScreen() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data: inventoryStepsBound } = useGetInventoryStepsBoundQuery(); 
  const { data: inventoryStepsLoose } = useGetInventoryStepsLooseQuery();
  const step = useSelector((state) => state.stepper.step);
  const bound = useSelector(showBound);

  const [previousBound, setPreviousBound] = useState(true);

  useEffect(() => {
    dispatch(setDamage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const handleClick = () => {
    dispatch(resetIsDamage());
    dispatch(resetStep());
  };

  window.onpopstate = () => {
      // dispatch(decrementStep());
      if (bound !== undefined) {
        setPreviousBound(bound);
      } else {
        dispatch(changeBound(previousBound));
      };   
  };
  
  return (
    <div className="container mx-auto p-10 sm:mt-10 lg:p-0 lg:mt-20">
      <div className={bound === true ? "grid sm:grid-cols-2 gap-10 mt-10  lg:grid-cols-4 lg:gap-8" :
        "grid sm:grid-cols-2 gap-12 mx-60 mt-10"}>
        { bound === true ? inventoryStepsBound && inventoryStepsBound?.map((inventoryStepBound) => 
          <FormCharStepCard 
            key={inventoryStepBound.id} 
            stepId={inventoryStepBound.id} 
            order={inventoryStepBound.order} 
            cardTitle={inventoryStepBound.description} 
            cardIcon={inventoryStepBound.icon} 
            isBound={inventoryStepBound.isBound}
            step={step}
          />
        ) : 
         inventoryStepsLoose && inventoryStepsLoose?.map((inventoryStepLoose) => 
          <FormCharStepCard
            key={inventoryStepLoose.id} 
            stepId={inventoryStepLoose.id} 
            order={inventoryStepLoose.order} 
            cardTitle={inventoryStepLoose.description} 
            cardIcon={inventoryStepLoose.icon} 
            isBound={inventoryStepLoose.isBound}
            step={step}
           />
         )
        }
      </div>
      <div className="flex flex-col items-center mt-16 sm:mt-20 mb-10 sm:mb-0" >
        <div className="flex items-center">
          <div className="ml-8 justify-center">
          <Link to="/main/identification" className="after:h-0">
            <InventoryButton
              type="button"
              onClick={handleClick}
            >
              {t('ToIdentificationScreen')}
            </InventoryButton>
          </Link>
          </div>      
        </div>
      </div>
    </div>
  );
});
