import React, { useEffect } from 'react';
import { useStopwatch } from 'react-timer-hook';
import { useDispatch } from 'react-redux';
import { setSeconds, setMinutes, setHour } from '../../store/slices/timerSlice';


export default function Timer() {
  const {hours, minutes, seconds } = useStopwatch({autoStart: true});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setHour(hours));
    dispatch(setMinutes(minutes));
    dispatch(setSeconds(seconds));
  });

  const formatTime = (time) => {
    return String(time).padStart(2, '0')
  };

  return (
    <div className='fixed bottom-5 right-0' >
      <div className='flex items-center mr-6 mt-10 text-xl font-semibold text-gray-400 invisible'>
        <span>{formatTime(hours)}</span>:<span>{formatTime(minutes)}</span>:<span>{formatTime(seconds)}</span>
      </div>
    </div>
  )
}