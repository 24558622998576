import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axios, setAuthToken } from "../axiosConfig";
import config from '../../config.json';

const JWT_TOKEN_KEY = config.token_key;

export const login = createAsyncThunk('login/login', async ({email, password}, { rejectWithValue }) => {
  try {
    const response = await axios.post('users/login', {email, password});
    return response.data;
  } catch (err) {
    return rejectWithValue('The login failed');
  };
});

export const logout = createAsyncThunk('login/logout', async () => {
  return;
});

export const registerUser = createAsyncThunk('login/registerUser', async ({name, email, password}, { rejectWithValue }) => {
  try {
    const response = await axios.post('users/register', {name, email, password});
    return response.data;
  } catch (err) {
    return rejectWithValue('Deze gebruiker is al geregistreerd');
  };
});

const loginSlice = createSlice({
  name: "login",
  initialState: {
    user: {
      id: "",
      name: "",
      email: "",
      password: "",
      roles: [],
    },
    token: "",
    ready: undefined,
    loading: false,
    error: "",
    registerError: null,
  },
  reducers: {
    resetRegisterError: (state) => {
      state.registerError = null;
    },
    resetEmail: (state) => {
      state.user.email = '';
    },
    resetLoginError: (state) => {
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
      console.log(action.payload)
      state.user.id = action.payload.user.id;
      state.user.name = action.payload.user.name;
      state.user.email = action.payload.user.email;
      state.user.password = action.payload.password;
      state.user.roles = action.payload.user.roles;
      state.token = action.payload.token;
      if(state.token) {
        localStorage.setItem(JWT_TOKEN_KEY, state.token);
        state.ready = true;
        setAuthToken(state.token);
      } else {
        localStorage.removeItem(JWT_TOKEN_KEY);
      }
    });
    builder.addCase(login.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      state.token = "";
      localStorage.removeItem(JWT_TOKEN_KEY);
      state.user = {};
      state.user.roles = [];
      state.ready = false;
      state.error = "";
    });
    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.user.name = action.payload.user.name;
      state.user.email = action.payload.user.email;
      state.user.password = action.payload.user.password;
      state.registerError = "";
    });
    builder.addCase(registerUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(registerUser.rejected, (state, action) => {
      state.loading = false;
      state.registerError = action.payload;
    });
  }
});

export const showId = state => state.login.user.id;
export const showEmail = state => state.login.user.email;
export const showName = state => state.login.user.name;
export const showRoles = state => state.login.user.roles;
export const showToken = state => state.login.token;
export const showReady = state => state.login.ready;
export const showLoginError = state => state.login.error;
export const showRegisterError = state => state.login.registerError;
export const isLoading = state => state.login.loading;
export const { resetRegisterError, resetEmail, resetLoginError } = loginSlice.actions;
export default loginSlice.reducer;