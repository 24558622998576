import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isLoading, login, showLoginError, resetLoginError, showReady } from '../../store/slices/loginSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '../helpers/Loading';
import InventoryButton from '../layouts/InventoryButton';

export default function LoginScreen() {
  const { t } = useTranslation();
  const { register, handleSubmit, reset, formState:{errors}, clearErrors } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authReady = useSelector(showReady);
  const loginError = useSelector(showLoginError);
  const isLoggingIn = useSelector(isLoading);
  const [show, setShow] = useState(false);

  const onSubmit = (data, event) => {
    // prevents the submit button from refreshing the page
    event.preventDefault();
    dispatch(login(data));
  };

  useEffect(() => {
    if(authReady) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authReady]);

  const showPassword = () => {
    setShow(true);
  };

  const hidePassword = () => {
    setShow(false);
  };

  const handleCancel = useCallback(() => {
    dispatch(resetLoginError());
    clearErrors(['email', 'password'])
    reset({email: '', password: ''});
  }, [reset, clearErrors, dispatch]);

  return (
    <>
      { isLoggingIn && <Loading className="flex flex-col items-center" /> }
      <form className="flex flex-col items-center mt-32" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-6 w-2/3 md:w-1/3">
          <label htmlFor="email" className="block mb-2 text-lg font-medium text-gray-900 dark:text-gray-300">{t('Email')}</label>
          <input 
            data-cy="email_input"
            type="email" 
            id="email" 
            className={ errors['email'] || loginError ? "bg-red-50 border border-red-500 placeholder-red-500 text-sm text-red-900 rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" : 
              "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" }
            placeholder={t('EmailPlaceholder')} 
            {...register('email', {required: true})} />
        </div>
        <div className="mb-6 w-2/3 md:w-1/3">
          <label htmlFor="password" className="block mb-2 text-lg font-medium text-gray-900 dark:text-gray-300">{t('Password')}</label>
          <div className={ isLoggingIn ? "relative opacity-10" : "relative opacity-70"}>
            { show ?
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                <FontAwesomeIcon icon="eye-slash" className="h-6 w-6 mt-11 mr-1 cursor-pointer" color="#111827" onClick={hidePassword}/>
              </div> :
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                <FontAwesomeIcon icon="eye" className="h-6 w-6 mt-11 mr-1 cursor-pointer" color="#111827" onClick={showPassword}/>
              </div>
            }
          </div>
          <input 
            data-cy="password_input"
            type={show ? "text" : "password"} 
            id="password" 
            className={ errors['password'] || loginError ? "bg-red-50 border border-red-500 placeholder-red-500 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" : 
                "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" }
            placeholder="********"
            {...register('password', {required: true})}  
          />
          
        </div>
        <div className="grid grid-cols-2 gap-6 my-4">
          <InventoryButton data-cy="submit_btn" type="submit">{t('LogIn')}</InventoryButton>
          <InventoryButton onClick={handleCancel}>{t('Cancel')}</InventoryButton>
        </div>
        {Object.keys(errors).length !== 0 ? 
          <div className="fixed bottom-2 right-6 p-4 mb-10 font-semibold text-red-700 bg-red-100 rounded-sm dark:bg-red-200 dark:text-red-800" role="alert">
            {t('EmptyRegister')}
          </div> : ""}
        {loginError ? 
          <div className="fixed bottom-2 right-6 p-4 mb-10 font-semibold text-red-700 bg-red-100 rounded-sm dark:bg-red-200 dark:text-red-800" role="alert">
            {t('LoginError')}
          </div> : ""}
      </form>
    </>
  )
}
