import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useFormPersist from 'react-hook-form-persist';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { incrementStep, resetStep } from '../../store/slices/stepperSlice';
import { postImage, updateInventoryData } from '../../store/slices/inventorySlice';
import { useTranslation } from "react-i18next";
import { useGetInventoryStepsIdentificationQuery, useGetInventoryFieldsByStepIdQuery, useGetInventoryStepsBoundQuery, useGetInventoryStepsLooseQuery, usePrefetch } from '../../features/api/apiSlice';
import InventoryField from '../layouts/InventoryField';
// import InventoryAlert from './InventoryAlert';
import InventoryAlertPopUp from './InventoryAlertPopUp';
import InventoryButton from '../layouts/InventoryButton';
import { showId } from '../../store/slices/loginSlice';
import InventoryForm from './InventoryForm';

const warningValues = [
  '76FAA2E4-B41E-41F9-A42A-A11D47955A01',
  '76FAA2E4-B41E-41F9-A42A-A11D47955A02',
  '76FAA2E4-B41E-41F9-A42A-A11D47955A03',
  '76FAA2E4-B41E-41F9-A42A-A11D47955A04',
  '76FAA2E4-B41E-41F9-A42A-A11D47955A05',
  '76FAA2E4-B41E-41F9-A42A-A11D47955A06',
  '76FAA2E4-B41E-41F9-A42A-A11D47955A07',
];

export default function IdentificationInventoryForm() {
  const { register, handleSubmit, formState: { errors }, clearErrors, control, watch, setValue } = useForm({ shouldUnregister: true });
  useFormPersist('formData', {
    watch,
    setValue,
    storage: window.localStorage,
    include: ['dropzone-file'], 
  });
  const { t } = useTranslation();
  const { stepId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bound = JSON.parse(sessionStorage.getItem('persist:bound')).bound;
  let step = useSelector((state) => state.stepper.step);
  const isDamage = useSelector((state) => state.stepper.isDamage);
  const userId = useSelector(showId)
  const { data: inventoryStepsIdentification } = useGetInventoryStepsIdentificationQuery();
  const { data: inventoryFields } = useGetInventoryFieldsByStepIdQuery(stepId);
  const { data: inventoryStepsBound } = useGetInventoryStepsBoundQuery();
  const { data: inventoryStepsLoose } = useGetInventoryStepsLooseQuery();
  const prefetchInstructions = usePrefetch('getInstructionsByInventoryFieldId');
  // const warningInventoryFields = step === 1 ? inventoryFields?.slice(0, 7) : null;
  // const idInventoryFields = step === 1 ? inventoryFields?.slice(7, inventoryFields.length) : inventoryFields;
  const lastAlertValue = control._formValues['76FAA2E4-B41E-41F9-A42A-A11D47955A07']

  const [alert, setAlert] = useState(false);
  // const [openAlert, setOpenAlert] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [inventoryFieldId, setInventoryFieldId] = useState('');

  console.log(watch())

  const prefetchInstructionsByInventoryFieldId = useCallback(() => {
    inventoryFields?.forEach((inventoryfield) => {
      prefetchInstructions(inventoryfield.id);
    });
  }, [prefetchInstructions, inventoryFields]);

  useEffect(() => {
    prefetchInstructionsByInventoryFieldId();
  }, [prefetchInstructionsByInventoryFieldId]);

  const handleAlertPopUpClose = (e) => {
    setAlert(false);
    navigate('/main/identification');
    dispatch(resetStep());
  };

  const onSubmit = (data, event) => {
    // // prevents the submit button from refreshing the page
    event.preventDefault();
    dispatch(incrementStep());
    const newData = Object.assign(data, {user: userId})
    dispatch(updateInventoryData(newData));
    if ((isDamage === false && step === inventoryStepsIdentification?.length) || isDamage === true) {
      navigate("/main/damage");
    } else {
      navigate("/main/identification");
      // navigate(-2)
    };
  };

  const handleFileInput = (e) => {
    const date = new Date().getTime();
    clearErrors("dropzone-file");
    setSelectedImage(e.target.files[0]);
    const image = e.target.files[0];
    const imageLabel = date+'.'+image.name;
    const blob = image.slice(0, image.size, 'image/jpeg');
    const uniqueImage = new File([blob], imageLabel, {type: 'image.jpeg'});
    dispatch(updateInventoryData({ imageLabel }));
    dispatch(postImage(uniqueImage));
  };

  const onClick = (e) => {
    clearErrors(e.target.id);
  };

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    };
  }, [selectedImage]);

  useEffect(() => {
    const fields = control._formValues;
    const fieldId = Object.keys(fields).find((key) => fields[key] === '1');
    if (warningValues[warningValues.length - 1] in control._formValues && lastAlertValue === '0') {
      const select = (arr, obj) => arr.reduce((val, key) => {
        obj.hasOwnProperty(key) && (val[key] = obj[key]);
        return val;
      }, {});
      const firstValues = select(warningValues, control._formValues);
      dispatch(updateInventoryData(firstValues));
      // setOpenAlert(false);
    } else if (warningValues.includes(fieldId) && Object.values(fields).includes('1')) {
      setInventoryFieldId(fieldId);
      setAlert(true);
    };
  }, [lastAlertValue, control._formValues, dispatch, control._formState]);

  return (
    <>
      {alert === true ?
        <InventoryAlertPopUp
          alert={alert}
          inventoryFieldId={inventoryFieldId}
          handleClose={handleAlertPopUpClose} s
        /> : ''}
      <InventoryForm 
        onSubmit={handleSubmit(onSubmit)}
        className="container mx-auto pl-12 pr-12 sm:pl-24 sm:pr-24 lg:pl-80 lg:pr-80 pt-6 mt-10"
      >
        {/* {step === 1 ?
          <InventoryAlert
            warningInventoryFields={warningInventoryFields}
            register={register}
            errors={errors}
            onClick={onClick}
            openAlert={openAlert}
            control={control}
          /> : ''} */}
        {inventoryFields && inventoryFields.map((inventoryField, i) =>
          <InventoryField
            key={inventoryField.id}
            id={inventoryField.id}
            label={inventoryField.description}
            type={inventoryField.type}
            dropDownValues={inventoryField.dropdownvalues}
            imageName={inventoryField.imageName}
            register={register}
            errors={errors}
            onClick={onClick}
            i={i}
          />)
        }
        {step === 1 && isDamage === false &&
          <div className="flex flex-col justify-center items-center">
            <label className="text-gray-600 text-xl font-bold mt-6 mb-1">{t('LoadImage')}</label>
            <label
              htmlFor="dropzone-file"
              className={errors['dropzone-file'] ? "flex flex-col justify-center items-center w-full h-64 bg-red-50 rounded-lg border-2 border-red-500 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600" :
                "flex flex-col justify-center items-center w-96 md:w-full h-64 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"}>
              {selectedImage === null ?
                <div className="flex flex-col justify-center items-center pb-2">
                  <svg aria-hidden="true" className={errors['dropzone-file'] ? "mb-3 w-10 h-10 text-red-900" : "mb-3 w-10 h-10 text-gray-400"} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                  <p className={errors['dropzone-file'] ? "mb-2 text-sm text-red-900" : "mb-2 text-sm text-gray-500 dark:text-gray-400"}><span className="font-semibold">{t('ClickUpload')}</span></p>
                  <p className={errors['dropzone-file'] ? "mb-2 text-sm text-red-900" : "text-xs text-gray-500 dark:text-gray-400"}>SVG, PNG, JPG {t('Or')} GIF (800x600px)</p>
                </div>
                : ''}
              <input
                id="dropzone-file"
                type="file"
                accept="image/*"
                className="hidden"
                {...register('dropzone-file', { required: true, value: 'image' })}
                onChange={handleFileInput}
              />
              {imageUrl && selectedImage && (
                <div className="center">
                  <img src={imageUrl} alt={selectedImage.name} className="h-52 rounded" onChange={handleFileInput} />
                </div>
              )}
            </label>
          </div>
        }
        <div className="mt-12 mb-10">
          <div className="flex justify-center">
            <InventoryButton
              data-cy="next_btn"
              type="submit"
            >
              {(step === (inventoryStepsBound?.length - 1) && bound === true) || (step === (inventoryStepsLoose?.length - 1) && bound === false) ?
                t('CompleteButton') : t('NextButton')}
            </InventoryButton>
            {/* <InventoryButton
              data-cy="next_btn"
              onClick={goToPreviousPage}
            >
              vorige
            </InventoryButton> */}
          </div>
        </div>
        {Object.keys(errors).length !== 0 ?
          <div className="fixed bottom-10 right-4 p-4 mb-4 font-semibold text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
            <span className="font-bold">{t('Alert')}</span> {t('EmptyFields')}
          </div> : ""}
      </InventoryForm>
    </>
  )
};