import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { resetIsDamage, resetStep } from '../../store/slices/stepperSlice';
import CountUp from 'react-countup';
import { setCount } from '../../store/slices/timerSlice';
import { useTranslation } from "react-i18next";
import {
  useGetInventoryFieldsByStepIdQuery, useGetInventoryStepsIdentificationQuery, usePrefetch,
  // useGetInventoryStepsLooseQuery, 
  useGetFormCharStepsBoundQuery,
} from '../../features/api/apiSlice';
import InventoryButton from '../layouts/InventoryButton';
import { useForm } from 'react-hook-form';
import useFormPersist from 'react-hook-form-persist';
import InventoryField from '../layouts/InventoryField';
import NavigationHeader from '../layouts/NavigationHeader'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import { emptySavedFormCharSteps, showSavedFormCharSteps } from '../../store/slices/inventoryStepSlice';
import InventoryForm from './InventoryForm';
import { showPostedInventoryId, updateInventory } from '../../store/slices/inventorySlice';
import DamagePopUp from './DamagePopUp';

const firstInventoryStep = '7f28c5f9-d711-4cd6-ac15-d13d71abaa01'.toUpperCase();
const excludedStep = '7f28c5f9-d711-4cd6-ac15-d13d71abaa03'.toUpperCase();

let noDamageValues = [
  '0',
  'Neen',
  'Geen',
];

const specificDamageValues = [
  'leder',
  'perkament',
  'textiel',
];

export default function FinishPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const hours = useSelector((state) => state.timer.hours);
  const minutes = useSelector((state) => state.timer.minutes);
  const seconds = useSelector((state) => state.timer.seconds);
  const count = useSelector((state) => state.timer.count);
  const savedFormCharSteps = useSelector(showSavedFormCharSteps);
  const postedInventoryId = useSelector(showPostedInventoryId);
  const [inventoryStepId, setInventoryStepId] = useState(firstInventoryStep);
  const [expanded, setExpanded] = useState(firstInventoryStep);
  const { data: inventoryFields } = useGetInventoryFieldsByStepIdQuery(inventoryStepId);
  const { data: idInventorySteps, isLoading: loadingIdInventorySteps } = useGetInventoryStepsIdentificationQuery();
  const { data: boundInventorySteps, isLoading: loadingBoundInventorySteps } = useGetFormCharStepsBoundQuery();
  // const { data: looseInventorySteps } = useGetInventoryStepsLooseQuery();
  const filteredIdInventorySteps = idInventorySteps?.filter((inventoryStep) => { return inventoryStep.id !== excludedStep });
  const filteredBoundInventorySteps = boundInventorySteps && boundInventorySteps.filter((f) => savedFormCharSteps.includes(f.description));
  const prefetchInventorySummaries = usePrefetch('getInventorySummaries');
  const [idAndBoundInventorySteps, setIdAndBoundInventorySteps] = useState([]);
  const [damagePresent, setDamagePresent] = useState(false);
  const [inventoryFieldId, setInventoryFieldId] = useState('');
  const [specificDamage, setSpecificDamage] = useState(null);

  // const idAndLoosedInventorySteps = [...filteredIdInventorySteps, ...looseInventorySteps];

  const { register, formState: { errors }, clearErrors, watch, setValue } = useForm();
  useFormPersist('formData', {
    watch,
    setValue,
    storage: window.localStorage,
  });

  const setInventoryStepsData = useCallback(() => {
    if (!loadingIdInventorySteps && !loadingBoundInventorySteps) {
      setIdAndBoundInventorySteps([...filteredIdInventorySteps, ...filteredBoundInventorySteps]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [loadingBoundInventorySteps, loadingIdInventorySteps]);

  useEffect(() => {
    setInventoryStepsData();
  }, [setInventoryStepsData]);

  const onClick = (e) => {
    clearErrors(e.target.id);
  };

  const getInventorySummaries = useCallback(() => {
    prefetchInventorySummaries({}, { force: true });
  }, [prefetchInventorySummaries]);

  useEffect(() => {
    getInventorySummaries();
  }, [getInventorySummaries]);

  const changeValue = (e) => {
    const fieldName = e.target.name;
    const valueToUpdate = e.target.value;
    const hasDamage = e.target.attributes.hasdamage?.value;
    const specificDamageValue = e.target.name;
    setInventoryFieldId(e.target.id);
    dispatch(updateInventory({ postedInventoryId, fieldName, valueToUpdate }))
    if (!noDamageValues.includes(valueToUpdate) && (hasDamage === 'true' || hasDamage === '1')) {
      setDamagePresent(true);
    };
    if (specificDamageValues.includes(valueToUpdate)) {
      setSpecificDamage(valueToUpdate);
    } else if (specificDamageValue.includes(specificDamageValues[0])) {
      setSpecificDamage(specificDamageValues[0]);
    } else if (specificDamageValue.includes(specificDamageValues[2])) {
      setSpecificDamage(specificDamageValues[2]);
    } else {
      setSpecificDamage(null);
    };
  };

  const handleDamagePopUpClose = useCallback((e) => {
    setDamagePresent(false);
  }, []);

  const reset = () => {
    dispatch(resetStep());
    dispatch(resetIsDamage());
    dispatch(setCount());
    localStorage.removeItem('formData');
    dispatch(emptySavedFormCharSteps());
  };

  return (
    <>
      <NavigationHeader />
      <Divider />
      <div className='container mx-auto grid grid-cols-4 p-2 lg:px-48 mt-4'>
        <div className="flex items-center justify-center display-inline bg-white">
          <div className={`overflow-hidden rounded-sm bg-[#0066cc] shadow-md duration-200 hover:scale-105 hover:shadow-xl flex flex-col items-center mt-10`}>
            <CountUp
              end={count}
              duration={1}
              className="mt-5 text-4xl text-slate-50"
            >
            </CountUp>
            <div className="p-5">
              <p className="text-2xl text-slate-50">{1 === count ? t('inventory') : t('Inventories')}</p>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center display-inline bg-white">
          <div className={`w-44 overflow-hidden rounded-sm bg-[#0066cc] shadow-md duration-200 hover:scale-105 hover:shadow-xl flex flex-col items-center mt-10`}>
            <CountUp
              end={hours}
              duration={1}
              className="mt-5 text-4xl text-slate-50"
            >
            </CountUp>
            <div className="p-5">
              <p className="text-2xl text-slate-50">{hours === 1 ? t('Hour') : t('Hours')}</p>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center display-inline bg-white">
          <div className={`w-44 overflow-hidden rounded-sm bg-[#0066cc] shadow-md duration-200 hover:scale-105 hover:shadow-xl flex flex-col items-center mt-10`}>
            <CountUp
              end={minutes}
              duration={1}
              className="mt-5 text-4xl text-slate-50"
            >
            </CountUp>
            <div className="p-5">
              <p className="text-2xl text-slate-50">{minutes === 1 ? t('Minute') : t('Minutes')}</p>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center display-inline bg-white">
          <div className={`w-44 overflow-hidden rounded-sm bg-[#0066cc] shadow-md duration-200 hover:scale-105 hover:shadow-xl flex flex-col items-center mt-10`}>
            <CountUp
              end={seconds}
              duration={1}
              className="mt-5 text-4xl text-slate-50"
            >
            </CountUp>
            <div className="p-5">
              <p className="text-2xl text-slate-50">{seconds === 1 ? t('Second') : t('Seconds')}</p>
            </div>
          </div>
        </div>
      </div>
      <h1 className='text-2xl text-slate-700 flex justify-center mt-14' >Overzicht van de ingevoerde gegevens</h1>
      <div className='container mx-auto pr-4 pl-4 pt-6'>
      {damagePresent === true ?
                <DamagePopUp
                  key={inventoryFieldId}
                  inventoryFieldId={inventoryFieldId}
                  damagePresent={damagePresent}
                  specificDamage={specificDamage}
                  register={register}
                  handleClose={handleDamagePopUpClose}
                /> : ''
              }
        {idAndBoundInventorySteps && idAndBoundInventorySteps.map((inventoryStep) =>
          <Accordion
            key={inventoryStep.id}
            expanded={inventoryStep.id === expanded}
            onChange={() => setExpanded(inventoryStep.id)}
            onClick={() => setInventoryStepId(inventoryStep.id)}
            sx={{ borderRadius: 1.5, mb: 1 }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className='text-slate-700' />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                border: 1.5,
                borderColor: '#334155',
                color: '#334155',
                borderTopLeftRadius: 2,
                borderTopRightRadius: 2,
                borderBottomLeftRadius: 2,
                borderBottomRightRadius: 2,
                height: 16
              }}
            >
              <Typography sx={{ fontSize: 18, fontWeight: 500 }}>{inventoryStep.description}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              
              {inventoryStep.id === expanded ?
                <InventoryForm className="container pl-10 pr-10 lg:pl-72 lg:pr-72 pt-9">
                  {inventoryFields && inventoryFields.map((inventoryField, i) =>
                    <InventoryField
                      key={inventoryField.id}
                      id={inventoryField.id}
                      label={inventoryField.description}
                      type={inventoryField.type}
                      hasDamage={inventoryField.hasDamage.toString()}
                      dropDownValues={inventoryField.dropdownvalues}
                      imageName={inventoryField.imageName}
                      register={register}
                      errors={errors}
                      onClick={onClick}
                      getValue={changeValue}
                      i={i}
                    />
                  )
                  }
                </InventoryForm>
                : ''
              }
            </AccordionDetails>
          </Accordion>
        )}
      </div>
      <div className="flex flex-col items-center mb-20" >
        <div className="flex flex-row items-center mt-16 ">
          <Link to="/main/identification" className="after:h-0">
            <InventoryButton
              data-cy="identification_next_btn"
              className="bg-[#0066cc] text-white text-base text-center font-semibold rounded-sm w-60 mr-2 hover:bg-white hover:text-[#0066cc] hover:text-base hover:border hover:border-[#0066cc] px-5 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              type="button"
              onClick={reset}
            >
              {t('NextInventory')}
            </InventoryButton>
          </Link>
          <Link to="/overview" className="after:h-0">
            <InventoryButton
              data-cy="identification_next_btn"
              className="bg-[#0066cc] text-white text-base text-center font-semibold rounded-sm w-60 mr-2 hover:bg-white hover:text-[#0066cc] hover:text-base hover:border hover:border-[#0066cc] focus:ring-4 px-5 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              type="button"
              onClick={reset}
            >
              {t('CompletedInventories')}
            </InventoryButton>
          </Link>
        </div>
      </div>
    </>
  )
}
