import React from 'react';
//import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetFormCharStepsBoundQuery } from '../../features/api/apiSlice';
import FormCharacteristic from '../layouts/FormCharacteristic';

export default function FormCharScreen() {
  const params = useParams();
  const { data: formCharsBound } = useGetFormCharStepsBoundQuery();
  const filteredFormCharsBound = formCharsBound?.filter((formchar) =>
    (formchar.inventoryStepId.toLowerCase()) === (params.stepId).toLowerCase());
  const numberOfFormChars = filteredFormCharsBound?.length;
  useGetFormCharStepsBoundQuery();

  return (
    <>
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-10 mt-16 mr-10 ml-10 sm:mr-40 sm:ml-40 mb-40'>
        {filteredFormCharsBound && filteredFormCharsBound?.map((formchar) =>
          <FormCharacteristic
            key={formchar.id}
            id={formchar.id}
            description={formchar.description}
            imageName={formchar.icon}
            inventoryStepId={formchar.inventoryStepId}
            order={formchar.order}
            isBound={formchar.isBound}
            numberOfFormChars={numberOfFormChars}
          />
        )}
      </div>
    </>
  )
}
