import { configureStore } from "@reduxjs/toolkit";
import stepperReducer from "./slices/stepperSlice";
import timerReducer from "./slices/timerSlice";
import inventoryStepReducer from "./slices/inventoryStepSlice";
import inventoryFieldReducer from "./slices/inventoryFieldSlice";
import inventoryReducer from "./slices/inventorySlice";
import loginReducer from "./slices/loginSlice";
import { apiSlice } from "../features/api/apiSlice";
import storage from "redux-persist/lib/storage/session"
import { persistReducer, persistStore } from "redux-persist";

const persistStepConfig = {
  key: 'step',
  whitelist : ['step', 'isDamage'],
  storage,
};

const persistBoundConfig= {
  key: 'bound',
  whitelist : ['bound'],
  storage,
};

const persistAuthConfig = {
  key: 'auth',
  whitelist : ['ready', 'user'],
  storage,
};

const persistedStepperReducer = persistReducer(persistStepConfig, stepperReducer);
const persistedInventoryReducer = persistReducer(persistBoundConfig, inventoryReducer);
const persistedAuthReducer = persistReducer(persistAuthConfig, loginReducer);

export const store = configureStore({
  reducer: {
    stepper: persistedStepperReducer,
    timer: timerReducer,
    inventoryStep: inventoryStepReducer, 
    inventoryField: inventoryFieldReducer,
    inventory: persistedInventoryReducer,
    login: persistedAuthReducer,
    [apiSlice.reducerPath]: apiSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
    .concat(apiSlice.middleware),
});

export const persistor = persistStore(store);