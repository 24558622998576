import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axios } from "../axiosConfig";

export const getInventoryFields = createAsyncThunk('inventoryField/getInventoryFields', async (stepId, { rejectWithValue }) => {
  try {
    const response = await axios.get(`inventoryfields/${stepId}`);
    return response.data;
  } catch (err) {
    return rejectWithValue('The inventoryfields could not be fetched');
  }
});

const inventoryFieldSlice = createSlice({
  name: "inventoryField",
  initialState: {
    data: [],
    loading: false,
    error: "",
  },
  extraReducers: (builder) => {
    builder.addCase(getInventoryFields.fulfilled, (state, action) => {
      state.loading = false;
      if(state.data !== []) {
        state.data = action.payload;
      } else {
        state.data = [];
      }
    });
    builder.addCase(getInventoryFields.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getInventoryFields.rejected, (state, action) => {
      console.log(action.payload);
      state.loading = false;
      state.error = action.payload;
    });
  }
});

export const showInventoryFields = state => state.inventoryField.data;
export default inventoryFieldSlice.reducer;