import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useFormPersist from 'react-hook-form-persist';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { incrementFormCharStep, resetFormCharStep, incrementStep } from '../../store/slices/stepperSlice';
import { updateInventoryData, postInventory, showInventory } from '../../store/slices/inventorySlice';
import { useTranslation } from "react-i18next";
import {
  useGetInventoryFieldsByStepIdQuery,
  useGetInventoryStepsBoundQuery,
  useGetInventoryStepsLooseQuery,
  useGetFormCharStepsBoundQuery,
  usePrefetch
} from '../../features/api/apiSlice';
import InventoryField from '../layouts/InventoryField';
import DamagePopUp from './DamagePopUp';
import InventoryButton from '../layouts/InventoryButton';
import InventoryForm from './InventoryForm';

let noDamageValues = [
  '0',
  'Neen',
  'Geen',
];

const specificDamageValues = [
  'leder',
  'perkament',
  'textiel',
];

export default function FormCharInventoryForm() {
  const { register, handleSubmit, formState: { errors }, clearErrors, watch, setValue } = useForm();
  useFormPersist('formData', { watch, setValue, storage: window.localStorage });
  const { t } = useTranslation();
  const { stepId } = useParams();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const bound = JSON.parse(sessionStorage.getItem('persist:bound')).bound;
  const persistedStep = parseInt(JSON.parse(sessionStorage.getItem('persist:step')).step, 10);
  const formCharStep = useSelector((state) => state.stepper.formCharStep);
  let step = useSelector((state) => state.stepper.step);
  const inventoryData = useSelector(showInventory);
  const { data: inventoryFields } = useGetInventoryFieldsByStepIdQuery(stepId);
  const { data: inventoryStepsBound } = useGetInventoryStepsBoundQuery();
  const { data: inventoryStepsLoose } = useGetInventoryStepsLooseQuery();
  const { data: formCharsBound } = useGetFormCharStepsBoundQuery();
  const prefetchDamageFields = usePrefetch('getDamageFieldsByInventoryFieldId');
  const numberOfFormCharStepsBound = inventoryStepsBound?.length;
  const numberOfFormCharStepsLoose = inventoryStepsLoose?.length;
  const filteredFormCharsBound = formCharsBound?.filter((formchar) =>
    (formchar.inventoryStepId.toLowerCase()) === (params.inventoryStepId).toLowerCase());

  const [damagePresent, setDamagePresent] = useState(false);
  const [inventoryFieldId, setInventoryFieldId] = useState('');
  const [specificDamage, setSpecificDamage] = useState(null);

  const prefetchDamageFieldsByInventoryFieldId = useCallback(() => {
    inventoryFields?.forEach((inventoryfield) => {
      prefetchDamageFields(inventoryfield.id);
    });
  }, [prefetchDamageFields, inventoryFields]);

  useEffect(() => {
    prefetchDamageFieldsByInventoryFieldId();
  }, [prefetchDamageFieldsByInventoryFieldId]);

  const getValue = (e) => {
    const value = (e.target.value).toLowerCase();
    const hasDamage = e.target.attributes.hasdamage?.value;
    const specificDamageValue = e.target.name;
    setInventoryFieldId(e.target.id);
    document.getElementById(e.target.id).blur();
    if (!noDamageValues.includes(value) && (hasDamage === 'true' || hasDamage === '1')) {
      setDamagePresent(true);
    };
    if (specificDamageValues.includes(value)) {
      setSpecificDamage(value);
    } else if (specificDamageValue.includes(specificDamageValues[0])) {
      setSpecificDamage(specificDamageValues[0]);
    } else if (specificDamageValue.includes(specificDamageValues[2])) {
      setSpecificDamage(specificDamageValues[2]);
    } else {
      setSpecificDamage(null);
    };
  };

  const handleDamagePopUpClose = (e) => {
    setDamagePresent(false);
  };

  const onClickError = (e) => {
    clearErrors(e.target.name);
  };

  const onTouch = (e) => {
    e.target.value = '';
    clearErrors(e.target.name);
  };

  const onSubmit = (data, event) => {
    // prevents the submit button from refreshing the page
    event.preventDefault();
    dispatch(updateInventoryData(data));
    if ((formCharStep === filteredFormCharsBound?.length && persistedStep === numberOfFormCharStepsBound)
      || (bound === false && formCharStep === filteredFormCharsBound?.length && persistedStep === numberOfFormCharStepsLoose)) {
      dispatch(postInventory(inventoryData));
      navigate("/complete");
    } else if (formCharStep === filteredFormCharsBound?.length) {
      dispatch(incrementStep());
      dispatch(resetFormCharStep());
      navigate(-2);
    } else {
      dispatch(incrementFormCharStep());
      navigate(-1);
    };
  };

  return (
    <>
      {damagePresent === true ?
        <DamagePopUp
          inventoryFieldId={inventoryFieldId}
          damagePresent={damagePresent}
          specificDamage={specificDamage}
          handleClose={handleDamagePopUpClose}
          register={register}
          // onClickError={onClickError}
          // onTouch={onTouch}
          // handleSubmit={handleSubmit}
          // useFormPersist={useFormPersist}
        /> : ''}
      <InventoryForm
        onSubmit={handleSubmit(onSubmit)}
        className="container mx-auto pl-10 pr-10 sm:pl-24 sm:pr-24 lg:pl-80 lg:pr-80 pt-6 mt-10"
      >
        {inventoryFields && inventoryFields.map((inventoryField, i) =>
          <InventoryField
            key={inventoryField.id}
            id={inventoryField.id}
            label={inventoryField.description}
            type={inventoryField.type}
            dropDownValues={inventoryField.dropdownvalues}
            hasDamage={inventoryField.hasDamage.toString()}
            register={register}
            errors={errors}
            getValue={getValue}
            imageName={inventoryField.imageName}
            onClick={onClickError}
            onTouch={onTouch}
            i={i}
          />)
        }
        <div className="mt-12 mb-10 ">
          <div className="flex justify-center">
            <InventoryButton
              data-cy="next_btn"
              type="submit"
            >
              {(step === (inventoryStepsBound?.length - 1) && bound === true) || (step === (inventoryStepsLoose?.length - 1) && bound === false) ?
                t('CompleteButton') : t('NextButton')}
            </InventoryButton>
          </div>
        </div>
        {Object.keys(errors).length !== 0 ?
          <div className="fixed bottom-10 right-4 p-4 mb-4 font-semibold text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
            <span className="font-bold">{t('Alert')}</span> {t('EmptyFields')}
          </div> : ""}
      </InventoryForm>
    </>
  )
};