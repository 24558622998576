import { createSlice } from "@reduxjs/toolkit";

export const timerSlice = createSlice({
  name: 'timer',
  initialState: {
    hours: 0,
    minutes: 0, 
    seconds: 0,
    count: 1
  },
  reducers: {
    setHour: (state, action) => { 
      state.hours = action.payload;
    }, 
    setMinutes: (state, action) => {
      state.minutes = action.payload;
    }, 
    setSeconds: (state, action) => {
      state.seconds = action.payload;
    }, 
    setCount: (state) => {
      state.count += 1;
    }, 
  }
});

export const { setHour, setMinutes, setSeconds, setCount } = timerSlice.actions;
export default timerSlice.reducer;