import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { resetStep } from '../../store/slices/stepperSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BookCard from '../layouts/BookCard';
import BookList from '../layouts/BookList';
import { useGetInventorySummariesQuery } from '../../features/api/apiSlice';
import InventoryButton from '../layouts/InventoryButton';

export default function OverviewScreen() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data: inventorySummaries } = useGetInventorySummariesQuery({}, { refetchOnMountOrArgChange: true });

  const [listView, setListView] = useState(false);
  const [search, setSearch] = useState('');
 
  const newInventory = () => {
    dispatch(resetStep());
    localStorage.removeItem('formData');
  };

  const changeToListView = () => {
    setListView(true);
  };

  const changeToTileView = () => {
    setListView(false);
  };

  const setSearchTerm = (e) => {
    setSearch(e.target.value);
  };

  const filteredInventories = inventorySummaries && inventorySummaries.slice().sort().filter((i) => {
    return i[1]?.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <>
      <div className="flex flex-col sm:flex-row items-center justify-between mt-8">
          <div className="sm:ml-20 lg:ml-16">
            <form>   
              <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300">Search</label>
              <div className="relative">
                  <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-auto hover: cursor-pointer" onClick={newInventory}>
                      <svg aria-hidden="true" className="w-5 h-5 text-slate-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                  </div>
                  <input type="search" 
                    id="default-search" 
                    onChange={setSearchTerm}
                    className="block p-2.5 pl-10 w-72 lg:w-96 text-sm text-gray-900 placeholder-slate-400 bg-gray-50 rounded-lg border border-slate-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                    placeholder={t('Search')} 
                    required />
              </div>
            </form>
          </div>
          <div className="flex items-center mt-6 sm:mt-0 sm:mr-20 lg:mr-14">
            <Link to="/main/identification" className="mr-2 after:h-0">
              <InventoryButton
                onClick={newInventory}
              >
                {t('NewInventory')}
              </InventoryButton>
            </Link>
            <InventoryButton
              className="bg-[#0066cc] text-white text-center rounded-sm lg:w-auto hover:bg-white hover:text-[#0066cc] hover:border hover:border-[#0066cc] dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800;"
              onClick={listView ? changeToTileView : changeToListView}
            >
              {listView ? <FontAwesomeIcon icon="grip" color="white" className="hover:text-[#0066cc] px-4 pt-[13px] pb-[11px] block"/> :
                <FontAwesomeIcon icon="list" color="white" className="hover:text-[#0066cc] px-4 pt-[13px] pb-[11px] block"/>
              } 
            </InventoryButton>
          </div>     
      </div>
      {listView ? 
        <div data-cy="inventory" className="grid grid-cols-1 mr-20 ml-20" >
        {filteredInventories && filteredInventories.map((inventory) => 
          <BookList
            key={inventory[(inventory.length) - 1]} 
            inventory={inventory} 
          />)   
        }
        </div>
        :
        <div data-cy="inventory" className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 mx-12" >
        {filteredInventories && filteredInventories.map((inventory) => 
          <BookCard 
            key={inventory[(inventory.length) - 1]} 
            inventory={inventory} 
          />)   
        }
        </div>
      };
    </>
  );
}