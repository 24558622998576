import React, { useRef } from 'react';
import config from '../../config.json';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { useDispatch, useSelector } from 'react-redux';
import { incrementFormCharStep, incrementStep, resetFormCharStep } from '../../store/slices/stepperSlice';
import { postInventory, showInventory } from '../../store/slices/inventorySlice';
import { useGetInventoryStepsBoundQuery, useGetInventoryStepsLooseQuery, useGetInventoryFieldsByStepIdQuery } from '../../features/api/apiSlice';
import { saveFormCharStep } from '../../store/slices/inventoryStepSlice';
import InventoryButton from './InventoryButton';

export default function FormCharacteristic({
  id,
  description,
  imageName,
  inventoryStepId,
  order,
  isBound,
  numberOfFormChars,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formCharStep = useSelector((state) => state.stepper.formCharStep);
  const inventoryData = useSelector(showInventory);
  const { data: inventoryStepsBound } = useGetInventoryStepsBoundQuery();
  const { data: inventoryStepsLoose } = useGetInventoryStepsLooseQuery();
  const numberOfFormCharStepsBound = inventoryStepsBound?.length;
  const numberOfFormCharStepsLoose = inventoryStepsLoose?.length;
  const persistedStep = JSON.parse(sessionStorage.getItem('persist:step')).step;
  useGetInventoryFieldsByStepIdQuery(id);
  const ref = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
  }

  const handleClickYes = () => {
    navigate(`/main/damage/inventory/${inventoryStepId}/${id}`);
    dispatch(saveFormCharStep(description));
  };

  const handleClickNo = () => {
    dispatch(incrementFormCharStep());
    if (formCharStep === numberOfFormChars && (persistedStep === numberOfFormCharStepsBound.toString()
      || (isBound === 0 && persistedStep === numberOfFormCharStepsLoose.toString()))) {
      dispatch(postInventory(inventoryData));
      navigate("/complete");
    } else if (formCharStep === numberOfFormChars) {
      navigate("/main/damage");
      dispatch(incrementStep());
      dispatch(resetFormCharStep());
    };
  };

  return (
    <div>
      <Card
        onClick={handleClick}
        ref={ref}
        className={`group relative ${order !== formCharStep ? "opacity-50 cursor-not-allowed" : ""} scroll-pt-28`}
        sx={{
          minWidth: '500px',
          backgroundImage: `url(${config.base_imageUrl}${imageName})`,
          backgroundSize: 'cover',
          borderRadius: '2px',
        }}>
        <CardContent
          className={`${order === formCharStep ? "formCharStep group-hover:-translate-y-8 group-hover:opacity-100 duration-500" : ""}`}
          sx={{
            height: { xs:'16vh', sm: '35vh', lg: '32vh' },
            display: 'grid',
            alignContent: 'center',
            // border: 0.1,
            // borderColor: '#0066cc'
          }}>
          <Typography
            align="center"
            sx={{
              fontSize: { xs: '22px', sm: '42px' },
              fontWeight: '900',
              color: '#0066cc',
              marginBottom: '20px',
            }}
          >
            {description}
          </Typography>
        </CardContent>
        <CardActions className={`absolute bottom-0 right-0 w-full h-0 flex flex-col justify-center items-center opacity-0 
        ${order === formCharStep ? "group-hover:h-4/5 group-hover:opacity-100 duration-500" : ""}`}>
          {/* <Typography
            className="py-4 sm:py-6"
            align="center"
            sx={{
              fontSize: { xs: '22px', sm: '45px' },
              fontWeight: 'bold',
              color: '#0066cc',
            }}>
            aanwezig?
          </Typography> */}
          <div className='grid grid-cols-2 gap-6'>
              <InventoryButton
                className="bg-white text-[#0066cc] text-lg text-center font-bold rounded-sm w-full border border-4 border-[#0066cc] hover:bg-[#0066cc] hover:text-white hover:font-bold hover:rounded w-24 py-1 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={handleClickYes}
              >
                Ja
              </InventoryButton>
            <InventoryButton 
              className="bg-white text-[#0066cc] text-lg text-center font-bold rounded-sm w-full border border-4 border-[#0066cc] hover:bg-[#0066cc] hover:text-white hover:font-bold hover:rounded w-24 py-1 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              onClick={handleClickNo}
            >
              Neen
            </InventoryButton>
          </div>
        </CardActions>
      </Card>
    </div>
  )
}
