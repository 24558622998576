import React from 'react';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { useDispatch, useSelector } from 'react-redux';
import { incrementStep, resetFormCharStep, resetStep } from '../../store/slices/stepperSlice';
import { postInventory, showInventory } from '../../store/slices/inventorySlice';
import { useGetInventoryFieldsByStepIdQuery, useGetInventoryStepsBoundQuery, useGetInventoryStepsLooseQuery } from '../../features/api/apiSlice';

export default function FormCharStepCard({stepId, order, cardTitle, cardIcon, step, isBound}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDamage = useSelector((state) => state.stepper.isDamage);
  const inventoryData = useSelector(showInventory);
  const { data: inventoryStepsBound } = useGetInventoryStepsBoundQuery(); 
  const { data: inventoryStepsLoose } = useGetInventoryStepsLooseQuery();
  useGetInventoryFieldsByStepIdQuery(stepId);

  const handleClickYes = () => {
    if (isDamage === false && step !== inventoryStepsLoose.length + 1){
      navigate("/main/damage/" + stepId);
    } else if (isDamage === true && step !== inventoryStepsBound.length + 1) {
      dispatch(resetFormCharStep());
      navigate("/main/damage/" + stepId);
    } else {
      dispatch(resetStep());
      navigate("/complete");
    };
  };

  const handleClickNo = () => {
    dispatch(incrementStep());
    if (isDamage === true && (step === inventoryStepsBound.length || (isBound === false && step === inventoryStepsLoose.length))) {
      dispatch(postInventory(inventoryData));
      dispatch(resetStep());
      navigate("/complete");
    };
  };

  return (
    <div>
      <Card 
        className={`bg-white lg:mt-12 group relative ${ isBound === true || isBound === 1 ? 'h-72 lg:h-72' : 
        isBound === false ? 'h-72':
        '' } overflow-hidden rounded-sm bg-zinc-100 shadow-md duration-200 hover:scale-105 hover:shadow-xl flex flex-col items-center sm:mt-10 
        ${step !== order ? 'opacity-50 cursor-not-allowed' : 'scale-105 shadow-xl'} ${order !== step ? "opacity-50 cursor-not-allowed" : ""}`}
        sx={{ 
          backgroundColor: '#f5f5f5',
          marginTop: 0, 
          marginRight: 0
        }}>
          <FontAwesomeIcon 
            icon={cardIcon} 
            color={'#334155'}
            className={ isBound === true || isBound === 1 ? `h-20 w-20 mt-16 ${step === order ? "group-hover:h-0 duration-300" : ""}` : 
            `h-24 w-24 mt-16 ${step === order ? "group-hover:h-0 duration-300" : ""}` }/>
      <CardContent 
        sx={{
          height: '34vh',
        }}>
        <Typography
          className={`${ order === step ? "text-slate-700 group-hover:-translate-y-8 group-hover:opacity-100 duration-500" : ""}`}
          align="center"
          sx={{ 
            fontSize: '27px',
            fontWeight: 'bold',
            marginTop: order === inventoryStepsBound.length - 1 ? '5px' : '20px'
          }}
         >
           {cardTitle}
        </Typography>
      </CardContent>
      <CardActions className={`absolute bottom-0 right-0 w-full h-0 flex flex-row justify-center items-start opacity-0 
        ${ order === step ? "group-hover:h-72 group-hover:text-3xl group-hover:opacity-100 duration-500" : ""}
        ${ order === inventoryStepsBound.length - 1 ? "group-hover:h-56" : ""}`}
      >
        <div>
          <Typography
            className="py-6 font-bold text-gray-700"
            align="center"
            sx={{ 
              fontSize: '27px',
              fontWeight: 'bold',
              marginTop: '10px', 
              marginBottom: '10px'
            }}
          >
            aanwezig?
          </Typography>
            <button className='bg-white text-base font-bold rounded-sm text-slate-700 border-2 border-slate-700 hover:border-transparent 
              hover:text-white hover:font-bold hover:bg-slate-600 px-2 py-1 w-20 mr-4'
              onClick={handleClickYes}
            >
              Ja
            </button>
            <button className='bg-white text-base font-bold rounded-sm text-slate-700 border-2 border-slate-700 hover:border-transparent 
              hover:text-white hover:font-bold hover:bg-slate-600 px-2 py-1 w-20'
              onClick={handleClickNo}
            >
              Neen
            </button>
          </div>
        </CardActions>
      </Card>
    </div>
  );
}
