import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { showReady, logout, showRoles, showName } from '../../store/slices/loginSlice';

export default React.memo(function NavigationHeader() {
  const { t, i18n } = useTranslation();
  const authReady = useSelector(showReady);
  const roles = useSelector(showRoles);
  const userName = useSelector(showName);
  const dispatch = useDispatch();

  const changeLanguage = (countryCode) => {
    let i18nLanguage = "";

    switch (selected) {
      case "GB": {
        i18nLanguage = "EN";
        break;
      }
      default: {
        i18nLanguage = selected;
        break;
      }
    }
    i18n.changeLanguage(i18nLanguage.toLowerCase());
    localStorage.setItem("UserLanguage", countryCode);
  };

  let localStorageUserLanguage = localStorage.getItem("UserLanguage");
  if (!localStorageUserLanguage) {
    localStorageUserLanguage = "NL";
  }

  const [selected, setSelected] = useState(localStorageUserLanguage);
  const [navExpanded, setNavExpanded] = useState(false);

  useEffect(() => {
    changeLanguage(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, roles]);

  const handleLogout = () => {
    if (authReady) {
      localStorage.removeItem('formData');
      dispatch(logout());
    }
  };

  return (
    <div className="overflow-visible mb-1">
      <nav className="flex items-center justify-between flex-wrap p-4">
        <Link to="/" className='after:h-0'>
          <div className="flex items-center flex-shrink-0 text-slate-700">
            <FontAwesomeIcon icon="book-open" className="h-9 w-9 md:ml-12 mt-2" />
            <span className="font-bold text-4xl text-slate-700 tracking-tight ml-3 mt-2">AppolloRate.</span>
          </div>
        </Link>
        <div className="block lg:hidden">
          <button
            type="button"
            className="flex items-center px-4 py-2.5 mt-4 md:mr-8 border rounded bg-white-200 text-white-200 border-slate-700 hover:text-slate-700 hover:border-slate-700 hover:bg-white"
            onClick={() => setNavExpanded(!navExpanded)}
          >
            <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
          </button>
        </div>
        <div className={navExpanded ? "w-full flex flex-col items-center ml-8" : "w-full block flex-grow lg:flex md:items-center lg:w-auto hidden md:hidden mr-6 mt-3"}
        >
          <div className={navExpanded ? "text-lg flex flex-col mt-8 items-center content-center" : userName ? "text-lg lg:flex-grow flex justify-start" : "text-lg lg:flex-grow flex justify-end" }
          >
            <a className="content-center text-xl" href="/">
              {t('Why')}
            </a>
            <a className="content-center text-xl" href="/">
              {t('Price')}
            </a>
            <a className="content-center text-xl" href="/">
              {t('Contact')}
            </a>
          </div>
          {userName ? 
            <div className="font-semibold text-base text-slate-700 mr-3 mt-1">
            <p></p>
            <p>Hallo, {userName}</p>
          </div> : ''
          }
          <Link to="/register" className="lg:mr-2 after:h-0">
            {roles && roles.includes('admin') && <button
              type="button"
              className={navExpanded ? "inline-block px-5 py-2.5 sm:px-6 sm:py-3 leading-none border rounded bg-slate-700 text-white border-slate-700 hover:border-slate-700 hover:text-slate-700 hover:bg-white mt-2 lg:mt-0" :
                "inline-block text-base px-4 py-2 leading-none border bg-white text-slate-700 border-slate-700 hover:border-white hover:text-white hover:bg-slate-700 mt-4 lg:mt-0"}
            >
              {t('Register')}
            </button>}
          </Link>
          <Link to={authReady ? "/" : "/login"} className="lg:mr-4 after:h-0">
            <button
              type="button"
              onClick={handleLogout}
              className={navExpanded ? "inline-block px-5 py-2.5 sm:px-6 sm:py-3 mb-1 leading-none border rounded bg-slate-700 text-white border-slate-700 hover:border-slate-700 hover:text-slate-700 hover:bg-white mt-2 lg:mt-0" :
                "inline-block text-base px-5 py-2 leading-none border bg-white text-slate-700 border-slate-700 hover:border-white hover:text-white hover:bg-slate-700 mt-4 lg:mt-0"}
            >
              {authReady ? t('LogOut') : t('LogIn')}
            </button>
          </Link>
          <select
            value={selected}
            onChange={(event) => {
              setSelected(event.target.value);
            }}
            className="text-base px-0 py-2 w-auto leading-none border-none focus:border-none focus:ring-white bg-white text-slate-700 mt-4 lg:mt-1"
          >
            <option value="NL">NL</option>
            <option value="EN">EN</option>
          </select>
        </div>
      </nav>
    </div>
  )
});
