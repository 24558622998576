import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { DialogContent, Typography } from '@mui/material';
import { useGetInstructionsByInventoryFieldIdQuery } from '../../features/api/apiSlice';
import { useTranslation } from "react-i18next";
import InventoryButton from '../layouts/InventoryButton';

export default function InventoryAlertPopUp({ alert, inventoryFieldId, handleClose }) {
  const { t } = useTranslation();
  const { data: instructions } = useGetInstructionsByInventoryFieldIdQuery(inventoryFieldId);

  return (
    <Dialog
      open={alert}
      fullWidth={true}
      maxWidth={'lg'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ textAlign: 'center', mt: 2, fontWeight: 'bold', fontSize: 26 }}
      >
        Voer onderstaande instructies uit:
      </DialogTitle>
      <DialogContent sx={{ marginLeft: 2 }}>
        {instructions && instructions.map((instruction) =>
          <List key={instruction.id} >
            <ListItem disablePadding alignItems='flex-start'>
              <ListItemText
                primary={
                  <Typography>
                    <span className='font-bold'>Stap {instruction.stepNumber}:</span> {instruction.text}
                  </Typography>
                }
              />
            </ListItem>
          </List>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-around', mb: 3 }} >
        <InventoryButton onClick={handleClose}>{t('NextInventory')}</InventoryButton>
      </DialogActions>
    </Dialog>
  );
}
