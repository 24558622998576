import * as React from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { showBound } from '../../store/slices/inventorySlice';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

export default React.memo(function HorizontalLabelPositionBelowStepper({step}) {
  const { t } = useTranslation();
  const isDamage = useSelector((state) => state.stepper.isDamage);
  const bound = useSelector(showBound);

  const steps = [
    t('Identification'),
    t('Packaging'),
    t('Inventory'),
  ];

  const damageBoundSteps = [
    t('Binding'),
    t('Construction'),
    t('Attachment'),
    t('CarrierMaterial'),
  ];

  const damageLooseSteps = [
    t('Attachment'),
    t('CarrierMaterial'),
  ];

  return (
    <Box sx={{ width: '100%', mt: 8 }}>
      <Stepper data-cy='steps_step' activeStep={step-1} alternativeLabel>
        {((isDamage === true && bound === true) ? damageBoundSteps 
            : isDamage === true && bound === false ? damageLooseSteps
            : steps).map((label) => (
          <Step  sx={{ 
            '& .MuiStepLabel-root .Mui-completed': {
              color: '#0066cc', // circle color (COMPLETED)
              fontWeight: 500,
              fontSize: 28
            },
            '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
              {
                color: '#0066cc', // Just text label (COMPLETED)
                fontSize: '1.2rem',
                fontWeight: 500,
              },
            '& .MuiStepLabel-root .Mui-active': {
              color: '#0066cc', // circle color (ACTIVE)
              fontWeight: 500,
              fontSize: 28
            },
            '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
              {
                // color: '#0284c7', // Just text label (ACTIVE)
                fontSize: '1.3rem',
                fontWeight: 500,
              },
            '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
              fill: 'white', // circle's number (ACTIVE)
              fontSize: '1rem'
            },
            '& .MuiStepIcon-root': {
              //color: '#a1a1aa', // circle color (ACTIVE)
              fontWeight: 500,
              fontSize: 28
            },
            '& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-alternativeLabel':
              {
                color: '#9ca3af', // Just text label (INACTIVE)
                fontSize: '1.1rem'
              },
            '& .MuiStepConnector-root.Mui-active .MuiStepConnector-line': {
                borderColor: '#0284c7',
                borderTopWidth: '2px'
            },
            '& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line': {
              borderColor: '#0284c7',
              borderTopWidth: '1.5px'
          },
          }} key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
});