import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from "react-router-dom";
import { showReady } from '../../store/slices/loginSlice';

export default function ProtectedRoute({ children }) {
  const isAuth = useSelector(showReady);

  return (
      isAuth ? (children) : (<Navigate to="/login" />)
  );
}
