import { createSlice } from "@reduxjs/toolkit";

export const stepperSlice = createSlice({
  name: 'stepper',
  initialState: {
    step: 1,
    formCharStep: 1,
    isDamage: false
  },
  reducers: {
    incrementStep: (state) => {
      state.step += 1;
    }, 
    decrementStep: (state) => {
      state.step -= 1;
    }, 
    resetStep: (state) => {
      state.step = 1;
    }, 
    setDamage: (state) => {
      state.isDamage = true;
    }, 
    resetIsDamage: (state) => {
      state.isDamage = false;
    },
    incrementFormCharStep: (state) => {
      state.formCharStep += 1;
    }, 
    decrementFormCharStep: (state) => {
      state.formCharStep -= 1;
    }, 
    resetFormCharStep: (state) => {
      state.formCharStep = 1;
    }, 
  }
});

export const { 
  incrementStep, 
  decrementStep, 
  resetStep, 
  setDamage, 
  resetIsDamage,
  incrementFormCharStep,
  decrementFormCharStep,
  resetFormCharStep, 
} = stepperSlice.actions;
export default stepperSlice.reducer;