import React from 'react';
import InventoryLabel from '../inventory/InventoryLabel';
import { Typography } from '@mui/material';

export default React.memo(function InventoryField({
  id,
  label,
  type,
  dropDownValues,
  hasDamage,
  imageName,
  register,
  errors,
  getValue,
  warning,
  damagepresent,
  onClick,
  onTouch,
  i,
}) {

  switch (type) {
    case 'text':
      return (
        <>
          <div className={damagepresent ? "mb-4 text-left" : "mb-6 text-left"}>
            <InventoryLabel label={label} imageName={imageName} />
            <input
              data-cy="text_input"
              className={errors[label] ? "bg-red-50 border border-red-500 text-red-900 placeholder-red-500 text-sm rounded-sm focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400" :
                "shadow appearance-none text-left border border-gray-300 rounded-sm w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"}
              {...register((label), { required: true })}
              id={id}
              type={type}
              hasdamage={hasDamage}
              onBlur={getValue}
              onClick={onClick}
              inputname={label}
            />
          </div>
        </>
      );
    case 'radio':
      return (
        <>
          <div className="mb-4 text-left">
            <div className={warning ? "w-8/12" : "mb-2"}>
              <InventoryLabel label={label} imageName={imageName} warning={warning} />
            </div>
            <div className="">
              <div className="inline-flex items-center" key={i}>
                <input
                  data-cy="radio_input"
                  type={type}
                  hasdamage={hasDamage}
                  className="hidden peer"
                  {...register((label), { required: true })}
                  id={`yes${i}`}
                  value="1"
                  onMouseLeave={() => document.getElementById(id).blur()}
                  onBlur={getValue}
                  onClick={onClick}
                  name={label}
                />
                <label htmlFor={`yes${i}`} className={errors[label] ? "text-red-500 font-medium text-base bg-red-50 border border-red-500 text-red-900 placeholder-red-50 rounded w-full py-1.5 px-9 rounded-lg hover:cursor-pointer" :
                  "shadow appearance-none text-center font-semibold text-gray-600 md:text-left border border-gray-300 rounded w-full py-1.5 px-9 rounded-lg cursor-pointer" +
                  "peer-checked:bg-[#0066cc] peer-checked:bg-[#0066cc] peer-checked:text-slate-50 hover:text-gray-600 hover:bg-gray-200 hover:cursor-pointer"}
                >
                  Ja
                </label>
              </div>
              <div className="inline-flex items-center ml-6 peer">
                <input
                  data-cy="radio_input"
                  type={type}
                  hasdamage={hasDamage}
                  className="hidden peer"
                  {...register((label), { required: true })}
                  id={`no${i}`}
                  value="0"
                  onMouseLeave={() => document.getElementById(id).blur()}
                  onBlur={getValue}
                  onClick={onClick}
                  name={label}
                />
                <label htmlFor={`no${i}`} className={errors[label] ? "text-red-500 font-medium text-base bg-red-50 border border-red-500 text-red-900 placeholder-red-50 rounded w-full py-1.5 px-7 rounded-lg hover:cursor-pointer" :
                  "shadow appearance-none text-center font-semibold text-gray-600 md:text-left border border-gray-300 rounded w-full py-1.5 px-7 rounded-lg cursor-pointer" +
                  "dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-sky-600 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700" +
                  "peer-checked:bg-[#0066cc] peer-checked:bg-[#0066cc] peer-checked:text-slate-50 hover:text-gray-600 hover:bg-gray-200 hover:cursor-pointer"}
                >
                  Neen
                </label>
              </div>
            </div>
          </div>
        </>
      )
    case 'select':
      return (
        <>
          <div className="flex flex-col w-full mb-6">
            <div className={warning ? "w-6/12" : damagepresent ? "" : "mb-1"}>
              <InventoryLabel label={label} imageName={imageName} />
            </div>
            <div className={warning ? "inline-block relative w-2/5" : "inline-block relative"}>
              <select
                data-cy="select_input"
                id={id}
                hasdamage={hasDamage}
                className={errors[label] ? "bg-red-50 border border-red-500 text-red-500 rounded-sm hover:cursor-pointer focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" :
                  "bg-gray-50 border border-gray-300 text-gray-600 rounded-sm hover:cursor-pointer focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"}
                {...register((label), {
                  required: true,
                  onChange: getValue
                })}
                onClick={onClick}
              >
                <option value="">Selecteer</option>
                {dropDownValues && dropDownValues.map((dropDownValue) =>
                  <option key={dropDownValue.id} value={dropDownValue.description}>{dropDownValue.description}</option>
                )}
              </select>
            </div>
          </div>
        </>
      )
    case 'number':
      return (
        <>
          <div className={damagepresent ? "mb-4 text-left" : "mb-6 text-left"}>
            <div className={damagepresent ? "mb-1" : "mb-1"}>
              <InventoryLabel label={label} imageName={imageName} />
            </div>
            <div>
              <input
                className={errors[label] ? "bg-red-50 border border-red-500 text-red-900 placeholder-red-500 text-sm rounded-sm focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400" :
                  "shadow appearance-none text-left border border-gray-300 rounded-sm w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:-webkit-inner-spin-button:h-40"}
                {...register((label), { required: true })}
                id={id}
                type={type}
                hasdamage={hasDamage}
                min={0}
                defaultValue={0}
                onMouseLeave={() => document.getElementById(id).blur()}
                onBlur={getValue}
                onClick={onClick}
                onTouchStart={onTouch}
                pattern={'[0-9]*'}
                inputMode={'numeric'}
              />
            </div>
          </div>
        </>
      )
    case 'title':
      return (
        <div className="flex flex-col md:flex-row items-center mb-3 w-full">
          <div className={damagepresent ? "w-7/12 ml-20 mr-6" : "md:w-5/12 lg:is-half is-offset-one-quarter"}>
            <Typography
              sx={{ fontWeight: 'bold', fontSize: 20 }}
              className="inline-block text-gray-600"
            >{label}</Typography>
          </div>
        </div>
      )
    case 'titleText':
      return (
        <>
          <div className="mb-5 text-left">
            <InventoryLabel label={label} imageName={imageName} />
            <div className="">
              <input
                className={errors[label] ? "bg-red-50 border border-red-500 text-red-900 placeholder-red-500 text-sm rounded-sm focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400" :
                  "shadow appearance-none text-center md:text-left border border-gray-300 rounded-sm w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:-webkit-inner-spin-button:h-40"}
                {...register((label), { required: true })}
                id={id}
                type={'number'}
                hasdamage={hasDamage}
                min={0}
                defaultValue={0}
                onMouseLeave={() => document.getElementById(id).blur()}
                onBlur={getValue}
                onClick={onClick}
              />
            </div>
          </div>
        </>
      )
    default:
      return (
        <>
          <div className="flex flex-col md:flex-row mb-6 w-full">
            <div className="md:w-5/12 lg:is-half is-offset-one-quarter">
              <InventoryLabel label={label} imageName={imageName} />
            </div>
            <div className="w-4/5 ml-10 md:ml-0 md:w-1/3">
              <textarea
                data-cy="textarea_input"
                className={errors[label] ? "bg-red-50 border border-red-500 text-red-500 placeholder-red-500 rounded-sm focus:ring-red-500 focus:border-red-500 block w-full p-2 dark:bg-red-100 dark:border-red-400" :
                  "shadow appearance-none border border-gray-300 rounded-sm w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"}
                {...register((label), { required: true })}
                id={id}
                type={type}
                hasdamage={hasDamage}
                onClick={onClick}
              >
              </textarea>
            </div>
          </div>
        </>
      );
  }
});
