import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { registerUser, showEmail, showRegisterError, resetRegisterError, resetEmail } from '../../store/slices/loginSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RegisterConfirmation from './RegisterConfirmation';
import InventoryButton from '../layouts/InventoryButton';

export default function RegisterScreen() {
  const { t } = useTranslation();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  const registerError = useSelector(showRegisterError);
  const email = useSelector(showEmail);
  // const navigate = useNavigate();

  const [passwordMatch, setPasswordMatch] = useState(true);
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);

  const onSubmit = (data, event) => {
    // prevents the submit button from refreshing the page
    event.preventDefault();
    if (data.password === data.passwordConfirm) {
      setPasswordMatch(true);
      dispatch(registerUser(data));
    } else {
      setPasswordMatch(false);
    };
  };

  const showPassword = () => {
    setShow(true);
  };

  const hidePassword = () => {
    setShow(false);
  };

  const handleCancel = useCallback(() => {
    reset({ name: '', email: '', password: '', passwordConfirm: '' });
    dispatch(resetRegisterError());
  }, [reset, dispatch]);

  const handleClose = () => {
    dispatch(resetEmail());
    handleCancel();
    setOpen(false);
  };

  useEffect(() => {
    return () => {
      dispatch(resetRegisterError());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (email) {
      setOpen(true)
    }
    if (registerError) {
      setOpen(false);
    }
  }, [registerError, email]);

  return (
    <form className="flex flex-col items-center mt-32" onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-6 w-2/5">
        <label htmlFor="name" className="block mb-2 text-lg font-medium text-gray-900 dark:text-gray-300">{t('Name')}</label>
        <input
          type="input"
          id="name"
          className={errors['name'] ? "bg-red-50 border border-red-500 placeholder-red-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" :
            "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"}
          placeholder={t('Name')}
          {...register('name', { required: true })} />
      </div>
      <div className="mb-6 w-2/5">
        <label htmlFor="email" className="block mb-2 text-lg font-medium text-gray-900 dark:text-gray-300">{t('Email')}</label>
        <input
          type="email"
          id="email"
          className={errors['email'] ? "bg-red-50 border border-red-500 placeholder-red-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" :
            "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"}
          placeholder={t('EmailPlaceholder')}
          {...register('email', { required: true })} />
      </div>
      <div className="mb-6 w-2/5">
        <label htmlFor="password" className="block mb-2 text-lg font-medium text-gray-900 dark:text-gray-300">{t('ChoosePassword')}</label>
        <div className="relative opacity-70">
          {show ?
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
              <FontAwesomeIcon icon="eye-slash" className="h-6 w-6 mt-11 mr-1 cursor-pointer" color="#111827" onClick={hidePassword} />
            </div> :
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
              <FontAwesomeIcon icon="eye" className="h-6 w-6 mt-11 mr-1 cursor-pointer" color="#111827" onClick={showPassword} />
            </div>
          }
        </div>
        <input
          type={show ? "text" : "password"}
          id="password"
          className={errors['password'] ? "bg-red-50 border border-red-500 placeholder-red-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" :
            "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"}
          {...register('password', { required: true, minLength: { value: 8, message: t('PasswordLength') } })} />
        {errors.password && <p className="text-red-500 ml-2">{errors.password.message}</p>}
      </div>
      <div className="mb-6 w-2/5">
        <label htmlFor="passwordConfirm" className="block mb-2 text-lg font-medium text-gray-900 dark:text-gray-300">{t('ConfirmPassword')}</label>
        <div className="relative opacity-70">
          {show ?
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
              <FontAwesomeIcon icon="eye-slash" className="h-6 w-6 mt-11 mr-1 cursor-pointer" color="#111827" onClick={hidePassword} />
            </div> :
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
              <FontAwesomeIcon icon="eye" className="h-6 w-6 mt-11 mr-1 cursor-pointer" color="#111827" onClick={showPassword} />
            </div>
          }
        </div>
        <input
          type={show ? "text" : "password"}
          id="matchPassword"
          className={errors['passwordConfirm'] ? "bg-red-50 border border-red-500 placeholder-red-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" :
            "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"}
          {...register('passwordConfirm', { required: true, minLength: { value: 8, message: t('PasswordLength') } })} />
        {errors.password && <p className="text-red-500 ml-2">{errors.password.message}</p>}
      </div>
      <div className="grid grid-cols-2 gap-8 my-4">
        <InventoryButton type="submit">{t('Register')}</InventoryButton>
        <InventoryButton onClick={handleCancel}>{t('Cancel')}</InventoryButton>
      </div>
      <RegisterConfirmation
        open={open}
        handleClose={handleClose}
      />
      {Object.keys(errors).length !== 0 ?
        <div className="fixed bottom-2 right-6 p-4 mb-10 font-semibold text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
          {t('EmptyRegister')}
        </div> : ""}
      {passwordMatch ? "" :
        <div className="fixed bottom-2 right-6 p-4 mb-10 font-semibold text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
          {t('PasswordsNotMatch')}
        </div>}
      {registerError ?
        <div className="fixed bottom-2 right-6 p-4 mb-10 font-semibold text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
          {t('RegisterError')}
        </div> : ""}
    </form>
  )
}