import React from 'react';
import { Outlet } from 'react-router-dom';
import { useGetInventoryStepsIdentificationQuery } from '../../features/api/apiSlice';
 
import NavigationHeader from '../layouts/NavigationHeader';
//import Footer from '../layouts/Footer';

export default function HomeScreen() {
  useGetInventoryStepsIdentificationQuery();

  return (
    <>
      <NavigationHeader />
      <>
        <Outlet />
      </>
      {/* <Footer /> */}
    </>
  )
};
