import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import config from '../../config.json';

export default function MouseOverPopover({ label, imageName, warning, damagePresent }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className="inline">
      {imageName === null ?
        <Typography
          sx={{ fontWeight: 600, fontSize: 'large' }}
          className="text-gray-600 mb-1 md:mb-0 pr-4"
        >
          {label}
        </Typography> :
        <>
          <Typography
            component={'span'}
            sx={{ fontWeight: 600, fontSize: 'large' }}
            className="inline text-gray-600 mb-1 md:mb-0"
          >
            {label}
            <Typography
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              className="inline mb-1 md:mb-0 pr-1.5"
              sx={{
                color: '#4b5563',
                fontWeight: 'bold',
                fontSize: 'large',
                cursor: 'pointer',
              }}
            >
              <FontAwesomeIcon icon={faImage} color="#0066cc" className="inline pl-1.5 h-4" />
            </Typography>
          </Typography>

          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <img
              src={`${config.base_imageUrl}${imageName}`}
              alt="example"
              loading="lazy"
              width="500"
            // height="180"
            />
          </Popover>
        </>
      }

    </div>
  );
}