import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { resetStep } from '../../store/slices/stepperSlice';
import { useGetInventoryStepsIdentificationQuery, usePrefetch } from '../../features/api/apiSlice';
import InventoryStepCard from '../layouts/InventoryStepCard';
import InventoryButton from '../layouts/InventoryButton';

export default function MainScreen() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let step = useSelector((state) => state.stepper.step);
  const { data: inventoryStepsIdentification } = useGetInventoryStepsIdentificationQuery();
  const prefetchInventorySummaries = usePrefetch('getInventorySummaries');

  useEffect(() => {
    prefetchInventorySummaries();
  }, [prefetchInventorySummaries]);
  
  const handleClick = () => {
    dispatch(resetStep());
  };

  return (
    <div id="main">
      <div id="inventory_steps" className='flex flex-col lg:flex-row justify-center mt-8 md:mt-0 md:mr-20 md:ml-20'>
        {inventoryStepsIdentification && inventoryStepsIdentification?.map((inventoryStep) =>
          <InventoryStepCard
            key={inventoryStep.id} 
            stepId={inventoryStep.id} 
            order={inventoryStep.order} 
            cardTitle={inventoryStep.description} 
            cardIcon={inventoryStep.icon} 
            step={step}
          />)}
      </div>
      <div className="flex flex-col items-center mt-16 sm:mt-20 mb-10 sm:mb-0" >
        <div className="flex items-center">
          <div className="ml-8 justify-center">
          <Link to="/overview" className="after:h-0">
            <InventoryButton
              onClick={handleClick}
            >
              {t('CompletedInventories')}
            </InventoryButton>
          </Link>
          </div>      
        </div>
      </div>
    </div>
  )
};
