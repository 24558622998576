import React from 'react';

export default function InventoryForm(props) {
  const { children, ...rest } = props;

  return (
    <form
      className="container pl-28 pr-28 pt-9"
      {...rest}
    >
      {children}
    </form>
  )
}
